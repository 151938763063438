import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { environment } from '../../../environment/environment';
import { ContentProcessor } from '../content.processor';
import { ContentService } from './content.service';
import { BrandService } from './brand.service';
import { AvailableModel } from '../../../../common/models/available-model';
import { Campaign } from '../../../../common/models/campaign';
import { AvailableModelsResponse } from '../../../../common/models/available-models-response';
import { AvailableDealersResponse } from '../../../../common/models/available-dealers-response';
import { ModelsResponse } from '../../../../common/models/models-response';
import { CampaignResponse } from '../../../../common/models/umbraco-responses/campaign-response';
import { Lead } from '../../../../common/models/lead';

@Injectable({
    providedIn: 'root',
})
export class CopService {
    limit = 9;
    skip = 0;
    filterString = '';

    loading$ = new BehaviorSubject<boolean>(true);
    loadingModels$ = new BehaviorSubject<boolean>(true);
    availableModels$ = new BehaviorSubject<AvailableModelsResponse>({
        pagination: {
            page: 1,
            total: 0,
        },
        results: [],
    });
    models$ = new BehaviorSubject<{ value: string; label: string }[]>([]);
    campaigns$ = new BehaviorSubject<Campaign[]>([]);

    private apiUrl = `${environment.apiUrl}/umbraco/api`;
    private copApiUrl = `${this.apiUrl}/CopApi`;
    private copApiKey = environment.copApiKey;

    constructor(
        private http: HttpClient,
        private brandService: BrandService,
        private contentService: ContentService,
        private contentProcessor: ContentProcessor,
        private translateService: TranslateService,
    ) {}

    getDealers(): Observable<{ pagination: any; results: any[] }> {
        return this.http.get<AvailableDealersResponse>(
            `${this.copApiUrl}/Dealers?brand=${this.brandService.brandNameString.toLowerCase()}&skip=0&limit=100`,
            {
                headers: this.headers,
            },
        );
    }

    getAvailableModels(): void {
        this.loading$.next(true);

        const availableModels: AvailableModel[] = [];

        this.http
            .get<AvailableModelsResponse>(this.getAvailableModelsUrl(), {
                headers: this.headers,
            })
            .pipe(
                switchMap((result) => {
                    availableModels.push(...result.results);
                    this.availableModels$.next(result);
                    this.loading$.next(false);
                    return this.http.get<AvailableModelsResponse>(
                        this.getAvailableModelsUrl(this.limit, Math.max(result.pagination.total - this.limit, 0)),
                        {
                            headers: this.headers,
                        },
                    );
                }),
            )
            .subscribe({
                next: (result: AvailableModelsResponse) =>
                    this.availableModels$.next({
                        pagination: result.pagination,
                        results: [...availableModels, ...result.results],
                    }),
            });
    }

    getAvailableModelsUrl(skip = this.skip, limit = this.limit): string {
        let url = `${this.copApiUrl}/AvailableModels?brand=${this.brandService.brandNameString.toLowerCase()}&skip=${skip}&limit=${limit}`;

        if (this.filterString.length) {
            url = url + `&${this.filterString}`;
        }

        return url;
    }

    getVehicle(vehicleOid: number): Observable<AvailableModel> {
        this.loading$.next(true);
        const url = `${this.copApiUrl}/Vehicle?brand=${this.brandService.brandNameString.toLowerCase()}&vehicleId=${vehicleOid}`;

        return this.http.get<AvailableModel>(url, {
            headers: this.headers,
        });
    }

    getModels(): void {
        this.loadingModels$.next(true);
        this.http
            .get<ModelsResponse>(this.getModelsUrl(), {
                headers: this.headers,
            })
            .subscribe({
                next: (response) => {
                    const models: { value: string; label: string }[] = [];
                    response.results.forEach((model) =>
                        models.push({
                            value: model.ModelSeries,
                            label: model.ModelSeries,
                        }),
                    );

                    this.models$.next(models);
                    this.loadingModels$.next(false);
                },
                error: () => this.loadingModels$.next(false),
            });
    }

    getModelsUrl(): string {
        let url = `${this.copApiUrl}/Models?brand=${this.brandService.brandNameString.toLowerCase()}`;

        if (this.filterString.length) {
            const params = this.filterString.split('&');
            const filteredParamsWithoutModelSeries = params.filter((param) => !param.startsWith('ModelSeries=')); // we don't need filter by modelSeries itself
            const updatedFilterString = filteredParamsWithoutModelSeries.join('&');
            url = url + `&${updatedFilterString}`;
        }
        return url;
    }

    getCampaigns(): Observable<Campaign[]> {
        const currentBrandId = this.brandService.setCurrentBrandId();

        if (!currentBrandId) {
            console.warn('No brand ID found. Setting campaigns to empty.');
            this.campaigns$.next([]);
            return of([]);
        }

        const currentDate = new Date().toISOString().split('T')[0];
        let campaignParams = new HttpParams({
            fromObject: {
                fetch: `descendants:${currentBrandId}`,
                fields: 'properties[$all]',
                skip: 0,
                take: 10,
            },
        });

        campaignParams = campaignParams.append('filter', 'contentType:campaign');
        campaignParams = campaignParams.append('filter', `start:${currentDate}`);
        campaignParams = campaignParams.append('filter', `end:${currentDate}`);

        return this.contentService
            .getContentItemsFromQuery<{ total: number; items: CampaignResponse[] }>(
                campaignParams,
                this.translateService.currentLang || this.translateService.defaultLang,
            )
            .pipe(
                take(1),
                map(results => {
                    const processedCampaigns = this.contentProcessor.processCampaignData(results.items);
                    this.campaigns$.next(processedCampaigns);
                    return processedCampaigns;
                }),
                catchError(err => {
                    console.error('Error fetching campaigns:', err);
                    this.campaigns$.next([]);
                    return of([]);
                })
            );
    }

    getCampaignsByCodes(campaignCodes: string[]): Observable<Campaign[]> {
        return this.getCampaigns().pipe(
            map(campaigns => campaigns.filter(campaign => campaignCodes.includes(campaign.code))),
            catchError(() => of([]))
        );
    }

    sendContactForm(contactForm: Lead): Observable<any> {
        const url = `${this.apiUrl}/ContactApi/SendContactForm`;

        return this.http.post<any>(url, contactForm, {
            headers: this.headers,
        });
    }

    private get headers(): HttpHeaders {
        return new HttpHeaders({
            ApiKey: this.copApiKey,
        });
    }
}
