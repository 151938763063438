import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ReplaySubject, BehaviorSubject, take } from 'rxjs';

import { webManiFest } from '../../../manifest';
import { BrandSettings } from '../../../../common/models/brand-settings';
import { GoogleTagManagerService } from './google-tag-manager.service';
import { CopBrandingResponse } from 'src/common/models/umbraco-responses/cop-branding-response';
import { environment } from 'src/frontend/environment/environment';
import { Brand } from 'src/common/models/enumeration/brand';
import { HttpParams } from '@angular/common/http';
import { ContentService } from './content.service';
import { TranslateService } from '@ngx-translate/core';
import { BrandResponse } from 'src/common/models/umbraco-responses/brand-response';
import { BrandProperties } from 'src/common/models/umbraco-responses/brand-properties';
import { HelperService } from '../../framework/services/helper.service';

@Injectable({
    providedIn: 'root',
})
export class BrandService {
    private static readonly DOMAIN_COUNTRY_MAP: { [key: string]: string } = {
        com: 'en-us',
        fi: 'fi-fi',
        lv: 'lv-lv',
        ee: 'et-ee',
        lt: 'lt-lt',
    };

    primaryColor = '';
    private brandSettingsSubject = new ReplaySubject<BrandSettings>(1);
    private loginBackgroundPhotosSubject = new ReplaySubject<string[]>(1);
    private copBrandSubject = new BehaviorSubject<CopBrandingResponse>({} as CopBrandingResponse);
    private brandIdSubject = new BehaviorSubject<string>('');

    public brandSettings$ = this.brandSettingsSubject.asObservable();
    public loginBackgroundPhotos$ = this.loginBackgroundPhotosSubject.asObservable();
    public copBrand$ = this.copBrandSubject.asObservable();
    public brandId$ = this.brandIdSubject.asObservable();

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private title: Title,
        private googleTagManagerService: GoogleTagManagerService,
        private contentService: ContentService,
        private translateService: TranslateService,
        private helperService: HelperService
    ) {
        this.getBrandColors();
        this.changeTheme(`theme-${this.brandNameString.toLowerCase()}.css`, 'light');
        this.setTrackers();
    }

    get brandNameString(): string {
        const hostname = window.location.hostname.toLowerCase();
        if (hostname.includes('dacia')) return Brand.Dacia;
        if (hostname.includes('renault')) return Brand.Renault;
        if (hostname.includes('nissan')) return Brand.Nissan;
        return Brand.Hyundai;
    }

    get countryIsoCode(): string {
        const hostname = window.location.hostname.toLowerCase();
        const isLocal = hostname.includes('.localnet');
        const defaultSuffix = environment.production ? 'fi' : 'com';

        let countryCode = defaultSuffix;

        if (isLocal) {
            const segments = hostname.split('.')[0].split('-');
            if (segments.length > 1 && BrandService.DOMAIN_COUNTRY_MAP[segments[1]]) {
                countryCode = segments[1];
            }
        } else {
            const suffix = hostname.split('.').pop() || defaultSuffix;
            if (BrandService.DOMAIN_COUNTRY_MAP[suffix]) {
                countryCode = suffix;
            }
        }

        return BrandService.DOMAIN_COUNTRY_MAP[countryCode] || BrandService.DOMAIN_COUNTRY_MAP[defaultSuffix];
    }

    setCurrentBrandId(): string | undefined {
        const brandName = this.brandNameString;
        const currentBrand = environment.brands.find((brand) => brand.name.toLowerCase() === brandName.toLowerCase());

        if (!currentBrand) {
            console.warn(`No brand node ID found for brand: ${brandName}`);
            this.resetBrandData();
            return undefined;
        }

        this.brandIdSubject.next(currentBrand.id);
        this.fetchBrandingData(currentBrand.id, brandName);

        return currentBrand.id;
    }

    private resetBrandData(): void {
        this.copBrandSubject.next({} as CopBrandingResponse);
        this.brandIdSubject.next('');
    }

    private fetchBrandingData(brandId: string, brandName: string): void {
        const params = new HttpParams({
            fromObject: {
                fetch: `descendants:${brandId}`,
                fields: 'properties[$all]',
                skip: '0',
                take: '10',
            },
        });

        this.contentService
            .getContentItemsFromQuery<{ total: number; items: CopBrandingResponse[] }>(
                params,
                this.translateService.currentLang || this.translateService.defaultLang,
            )
            .pipe(take(1))
            .subscribe({
                next: (response) => this.handleBrandingResponse(response, brandName),
                error: (error) => this.handleBrandingError(error),
            });
    }

    private handleBrandingResponse(response: { total: number; items: CopBrandingResponse[] }, brandName: string): void {
        this.updateCopBrand(response, brandName, (items) =>
            items.find(
                (item) =>
                    item.contentType === 'copCountryPerBrand' && item.cultures && item.cultures[this.countryIsoCode],
            ),
        );
    }

    private updateCopBrand(
        response: { total: number; items: CopBrandingResponse[] },
        brandName: string,
        finderFn: (items: CopBrandingResponse[]) => CopBrandingResponse | undefined,
    ): void {
        if (!response.items || response.items.length === 0) {
            this.emitEmptyCopBrand(brandName);
            return;
          }
      
          const matchingBrand = finderFn(response.items);
      
          if (!matchingBrand) {
            this.emitEmptyCopBrand(brandName);
            return;
          }

        this.copBrandSubject.next(matchingBrand);
    }

    private emitEmptyCopBrand(context: string): void {
        console.warn(`No copBrand found for brand/context: ${context}`);
        this.copBrandSubject.next({} as CopBrandingResponse);
      }

    private handleBrandingError(error: any): void {
        console.error('Error fetching brand data:', error);
        this.copBrandSubject.next({} as CopBrandingResponse);
    }

    getBrandLogo(type: 'white' | 'dark'): string {
        return `assets/images/logo/${this.brandNameString.toLowerCase()}-logo-${type}.svg`;
    }

    get brandColor(): string {
        if (window.location.hostname.indexOf('dacia') > -1) {
            return '#000000';
        } else if (window.location.hostname.indexOf('renault') > -1) {
            return '#000000';
        } else {
            return this.primaryColor;
        }
    }

    changeTheme(theme: string, colorScheme: string) {
        const themeLink = <HTMLLinkElement>this.document.getElementById('theme-css');
        const newHref = themeLink.getAttribute('href')!.replace('theme.css', theme);

        this.replaceThemeLink(newHref);
        this.replaceFaviconLink();
        this.setThemeColor();
        this.setWebManifest();

        this.title.setTitle(`${this.brandNameString} Cars`);
    }

    private replaceThemeLink(href: string) {
        const id = 'theme-css';
        const themeLink = <HTMLLinkElement>this.document.getElementById('theme-css');

        const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

        cloneLinkElement.setAttribute('href', href);
        cloneLinkElement.setAttribute('id', id + '-clone');

        themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

        cloneLinkElement.addEventListener('load', () => {
            themeLink.remove();
            cloneLinkElement.setAttribute('id', id);
        });
    }

    private replaceFaviconLink() {
        const faviconLink = <HTMLLinkElement>this.document.getElementById('favicon');
        faviconLink.setAttribute('href', `assets/icons/${this.brandNameString.toLowerCase()}-favicon.ico`);
    }

    private setThemeColor() {
        const themeColorLink = <HTMLLinkElement>this.document.getElementById('theme-color');
        themeColorLink.setAttribute('content', `${this.brandColor}`);
    }

    private setWebManifest() {
        const stringManifest = JSON.stringify(webManiFest)
            .replace(new RegExp(/BASE_URL/g), window.location.origin)
            .replace(new RegExp(/BRAND_NAME/g), this.brandNameString.toLowerCase());

        const blob = new Blob([stringManifest], { type: 'application/json' });
        const manifestURL = URL.createObjectURL(blob);
        const manifestLink = this.document.querySelector('#web-manifest');

        if (!manifestLink) {
            return;
        }

        manifestLink.setAttribute('href', manifestURL);
    }

    private setTrackers(): void {
        if (this.brandNameString.toUpperCase() === 'HYUNDAI') {
            this.googleTagManagerService.setGoogleTagManagerScript();
        }
    }

    private getBrandColors() {
        const params = new HttpParams({
          fromObject: {
            filter: 'contentType:copBrand',
            fields: 'properties[$all]',
          },
        });
    
        this.contentService
      .getContentItemsFromQuery<{ total: number; items: BrandResponse[] }>(
        params,
        this.helperService.currentLanguage,
      )
      .pipe(take(1))
      .subscribe({
        next: (brandResponse) => {
          if (!brandResponse || !brandResponse.items || !brandResponse.items.length) {
            return;
          }

          const matchedBrand = brandResponse.items.find(
            (item) => item.name.toLowerCase() === this.brandNameString.toLowerCase(),
          );

          if (!matchedBrand) {
            return;
          }

          const { primaryColor, secondaryColor, lighterColor } = matchedBrand.properties;

          this.applyDynamicCssVariables({
            primaryColor: primaryColor ?? '#002c5f',
            secondaryColor: secondaryColor ?? '#2160aa',
            lighterColor: lighterColor ?? '#e9f1f7',
          });
        },
        error: (err) => {
          console.error('Error fetching brand colors:', err);
        },
      });
  }

  private applyDynamicCssVariables(props: BrandProperties): void {
    const rootStyle = this.document.documentElement.style;

    if (props.primaryColor) {
      rootStyle.setProperty('--brand-primary', props.primaryColor);
    }
    if (props.secondaryColor) {
      rootStyle.setProperty('--brand-secondary', props.secondaryColor);
    }
    if (props.lighterColor) {
      rootStyle.setProperty('--brand-lighter', props.lighterColor);
    }
  }
}
