import { DateTime } from 'luxon';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root',
})
export class HelperService {
    constructor(private translateService: TranslateService) {}

    formatCurrency(value: number | null | undefined, maximumFractionDigits = 2) {
        if (typeof value === 'undefined' || value === null) {
            return '-';
        } else {
            return new Intl.NumberFormat('fi-FI', {
                style: 'currency',
                currency: 'EUR',
                maximumFractionDigits,
            }).format(value);
        }
    }

    formatNumber(value: number | null | undefined) {
        if (typeof value === 'undefined' || value === null) {
            return '-';
        } else {
            return new Intl.NumberFormat('fi-FI').format(value);
        }
    }

    formatToFinnishDate(value: Date | null | undefined) {
        if (typeof value === 'undefined' || value === null) {
            return '-';
        } else {
            return new Intl.DateTimeFormat('fi-FI').format(value);
        }
    }

    sortAlphabetically(sortProperty: string) {
        return (itemA: any, itemB: any) => {
            const nameA = itemA[sortProperty].toLowerCase();
            const nameB = itemB[sortProperty].toLowerCase();

            return nameA.localeCompare(nameB);
        };
    }

    convertLocalDateToUtc(date: Date): Date {
        const utcOffset = DateTime.fromJSDate(date).offset;
        const utcStartOfDay = DateTime.fromJSDate(date, { zone: 'UTC' }).plus({ minutes: utcOffset }).startOf('day');

        return utcStartOfDay.toJSDate();
    }

    get currentLanguage(): string {
        return this.translateService.currentLang || this.translateService.defaultLang;
    }
}
